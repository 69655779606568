// @import "base/variables";

@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts" !default;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;

// Non theme colors
$transparent: transparent;

// General theme colors
$white: #fff !default; //base-white
$black: #000 !default; //base-black
$ghost: #c8ced4; //netural-normal
$error: #f00; //base-error
$shuttleGrey: #5e6b78; //netural-dark
$base-light: #f3f4f6; //netural-light
$fernFrond: #3f7621; //base-approve

// organization 0 - Classic
$congressBlue: #004994; // primary
$scienceBlue: #006cdb; // primary-light
$prussianBlue: #002347; // primary-dark
// $fernFrond - defined above // secondary
$mantis: #7cce50; // secondary-light
$dell: #33601b; // secondary-dark

// organization 1 - Gananot
$bouquet: #a863a5; //primary
$orchid: #ce7eca; //primary-light
$jon: #311b30; //primary-dark
$scooter: #23b9d6; //secondary
$skyBlue: #7cd8e9; // secondary-light
$genoa: #167283; // secondary-dark

// organization 2 - Maccabi
$toryBlue: #0f4da6; //primary
$cornflowerBlue: #5b98f0; //primary-light
$downriver: #062146; //primary-dark
$cerise: #cf24a2; //secondary
$hotPink: #e567c3; // secondary-light
$velvet: #6d1355; // secondary-dark

// organization 3 - Individual
$bamboo: #db690a; //primary
$carrotOrange: #f58426; //primary-light
$asphalt: #180c01; //primary-dark
$darkCerulean: #004a80; //secondary
$peacockBlue: #0067b1; // secondary-light
$blueWhale: #002d4d; // secondary-dark

// organization 4 - Public
// $fernFrond - defined above primary
// $orchid - defined above - primary-light
// $dell - defined above - primary-dark
// $congressBlue - defined above - secondary
// $scienceBlue - defined above - secondary-light
// $prussianBlue - defined above - secondary-dark

$blue: #0070d2 !default;
$green: #008827 !default;
// primary is replaced by css variable --skin-primary-color-1
$red: #c00 !default;
$success: $green !default;
$danger: $red !default;
$light-blue: #7ed0ee !default;
$netural-color: $shuttleGrey;
$netural-color-second: $ghost;

$primary-border: 1px solid var(--primary);
$netural-border: 1px solid $ghost;
$netural-border-another: 1px solid $shuttleGrey;

// Consolidated values
$grey1: #f9f9f9 !default;
$grey2: #eee !default;
$grey3: #ccc !default;
$grey4: #999 !default;
$grey5: #666 !default;
$grey6: #444 !default;
$grey7: #222 !default;
$grey8: #333 !default;
$grey-transparent-1: rgba(0, 0, 0, 0.65) !default;
$grey-transparent-2: rgba(0, 0, 0, 0.25) !default;

$light-gray: $grey1 !default;
$slightly-darker-gray: $grey4 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$horizontal-rule-grey: $grey3 !default;
$product-number-grey: $grey3 !default;
$horizontal-border-grey: $grey4 !default;
$menu-link: $grey6 !default;
$close-menu-bg: $grey2 !default;
$link-color: $dark-gray !default;
$hr-border-color: $grey3 !default;

$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;
$global-border-radius: 8px;

$state-danger-text: #fff !default;
$alert-success-text: #fff !default;

// Font sizes
$base16-14px: 0.875em !default;

// Bootstrap overrides
$body-bg: $grey1 !default;
$card-cap-bg: $white !default;

// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;

// breadcrumb
$breadcrumb-bg: transparent !default;

// table border
$table-border-color: $grey3 !default;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;

// Font variables
$font-family-sans-serif:            "Assistant", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-title:                 #{$font-family-sans-serif} !default;
$base-font-family:                  $font-family-title !default;
$font-family-base:                  $font-family-sans-serif !default;
$font-transform-base:               none !default;
$font-letter-spacing-base:          normal !default;
$font-transform-title:              uppercase !default;
$font-letter-spacing-title:         normal !default;

$font-size-base: 1rem; // sets base font size applied to html element
$font-size-body: $font-size-base; // font size applied to body element
$font-size-xs: $font-size-base * 0.75;
$line-height-base: 1.5 !default;
$body-color: var(--primary); //bootstrap override

// Weights
$font-weight-extraLight:200 !default;
$font-weight-light:     300 !default;
$font-weight-regular:   400 !default;
$font-weight-medium:    500 !default;
$font-weight-semiBold:  600 !default;
$font-weight-bold:      700 !default;
$font-weight-extraBold: 800 !default;

$font200: $font-weight-extraLight;
$font300: $font-weight-light;
$font400: $font-weight-regular;
$font500: $font-weight-medium;
$font600: $font-weight-semiBold;
$font700: $font-weight-bold;
$font800: $font-weight-extraBold;

$font-style-italic:                 italic !default;
$font-weight-base:                  $font-weight-regular !default;

// Headings
// h1 font-size and line-height
$h1-font-size-mobile:               30px;
$h1-line-height-mobile:             0.9;

$h1-font-size-tablet:               30px;
$h1-line-height-tablet:             0.9;

$h1-font-size-desktop:              51px;
$h1-line-height-desktop:            0.9;
$h1-letter-spacing:                 -1.02px;

// h2 font-size and line-height
$h2-font-size-mobile:               25px;
$h2-line-height-mobile:             1;

$h2-font-size-tablet:               25px;
$h2-line-height-tablet:             1;

$h2-font-size-desktop:              35px;
$h2-line-height-desktop:            0.9;
$h2-letter-spacing:                 0;

// h3 font-size and line-height
$h3-font-size-mobile:               20px;
$h3-line-height-mobile:             1;

$h3-font-size-tablet:               20px;
$h3-line-height-tablet:             1;

$h3-font-size-desktop:              30px;
$h3-line-height-desktop:            1;
$h3-letter-spacing:                 -0.6px;

// h4 font-size and line-height
$h4-font-size-mobile:               18px;
$h4-line-height-mobile:             1.33;
$h4-font-size-tablet:               26px;
$h4-line-height-tablet:             1;
$h4-font-size-desktop:              21px;
$h4-line-height-desktop:            1.38;
$h4-letter-spacing:                 0;

// h5 font-size and line-height
$h5-font-size-mobile:               16px;
$h5-line-height-mobile:             1.5;
$h5-font-size-tablet:               26px;
$h5-line-height-tablet:             1;
$h5-font-size-desktop:              18px;
$h5-line-height-desktop:            1.61;
$h5-letter-spacing:                 0;

$h6-font-size: $font-size-xs; //bootstrap override

// Paragraphs
$paragraph-font-size: $font-size-base;
$paragraph-line-height: 24px;
$feature-paragraph-font-size: $font-size-base * 1.3125;
$feature-paragraph-line-height: 29px;
$intro-paragraph-font-size: 16px;
$intro-paragraph-line-height: 1.44;
$small-paragraph-font-size: $font-size-base * 0.75;
$small-paragraph-line-height: 1.5;
$medium-paragraph-font-size: $font-size-base * 0.875;
$medium-paragraph-line-height: 2.07;

// Labels

$label-xl: $font-size-base * 0.875;
$label-font-size: $font-size-base * 0.75;
$label-text-color: $black;
$label-xl-text-color: var(--primary);

//--------------------------------
//  LINKS
//--------------------------------

$link-color: var(--primary); //bootstrap override
$link-hover-color: var(--primary); //bootstrap override
$link-decoration: none;
$link-hover-decoration: none;
$link-primary-font-size: $font-size-base;
$link-primary-line-height: 1.5;
$link-secondary-font-size: $font-size-base * 0.875;
$link-secondary-line-height: 1;
$main-link-navigation-hover-color: $black;

// Bootstrap spacers - Units of 8px
$spacer: 1rem; // 16px
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: ($spacer * 0.5),
        // 8px
        2: $spacer,
        // 16px
        3: ($spacer * 1.5),
        // 24px
        4: ($spacer * 2),
        // 32px
        5: ($spacer * 2.5),
        // 40px
        6: ($spacer * 3),
        // 48px
        7: ($spacer * 3.5),
        // 56px
        8: ($spacer * 4),
        // 64px
        9: ($spacer * 4.5),
        // 72px
        10: ($spacer * 5),
        // 80px
        11: ($spacer * 5.5),
        // 88px
        12: ($spacer * 6),
        // 96px
        13: ($spacer * 6.5),
        // 104px
        14: ($spacer * 7)
        // 112px
    ),
    $spacers
);

//This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
    (
        25: 25%,
        33: 33.3333%,
        50: 50%,
        66: 66.6666%,
        75: 75%,
        100: 100%
    ),
    $sizes
);

// Set the number of columns and specify the width of the gutters.
$grid-breakpoints: (
    xs: 0,
    sm: 321px,
    md: 1023px,
    lg: 1280px,
    xl: 1680px,
) !default;

$container-max-widths: (
    md: 720px,
    lg: 973px,
    xl: 1280px,
    xxl: 1680px
) !default;

$ugly-viewport: 466px;
$grid-columns: 12;
$grid-gutter-width: 16px;
$max-content-width: 1120px;
$max-carousel-width: 1440px;
$max-container-width: 2080px;

//--------------------------------
//  Z-Index
//--------------------------------
$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-4: 4;

//--------------------------------
//  Mobile Menu
//--------------------------------
$menu-background-color: $white;
$slide-out-animation: right 0.5s cubic-bezier(0, 1, 0.5, 1);

//--------------------------------
//  Forms
//--------------------------------
$input-border-radius: 8px;
$input-border: 2px solid $ghost;
$input-height: 46px;
$input-padding: 12px 16px 12px 52px;
$input-color: $shuttleGrey;
$input-default-background: $white;
$input-disabled-background: $base-light;
$checkbox-border-radius: 5px;
$checkbox-border: 1px solid $ghost;
$checkbox-box-shadow: 0 3px 5px 0 rgba(46, 46, 66, 0.08);
$option-selected-background: $base-light;
$select-border-radius: 8px;
$select-box-shadow: 0 1px 10px 0 $ghost;
