@import "variables";

.swiper {
    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: "";
    }

    .swiper-button-prev,
    .swiper-button-next {
        box-sizing: border-box;
        width: 45px;
        height: 58px;
        padding: 10px;
        top: calc(50% - 29px);

        svg {
            fill: var(--primary);
            filter: drop-shadow(0 4px 4px rgba($black, 0.25));
        }
    }

    &.product-wrapper-inner {
        .swiper-button-prev,
        .swiper-button-next {
            svg {
                fill: $white;
            }
        }
    }

    .swiper-button-prev {
        left: 10px;
    }

    .swiper-button-next {
        right: 10px;

        svg {
            transform: rotate(180deg);
        }
    }

    .swiper-pagination {
        bottom: 16px;

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            margin: 0 5px;
            background: none;
            border: $primary-border;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: var(--primary);
            }
        }
    }
}
